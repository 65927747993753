import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from "react-router-dom";

const MOUNT_NODE = document.getElementById('app')

const render = () => {
  const App = require('./components/app').default
  ReactDOM.render((
    <Router>
      <App />
    </Router>
      ), MOUNT_NODE)


  
}

render()

// What is module.hot?
if (module.hot) {
  module.hot.accept(['./components/app'], () =>
    setImmediate(() => {
      ReactDOM.unmountComponentAtNode(MOUNT_NODE)
      render()
    }),
  )
}
