/* eslint-disable */
import React, { Component } from "react";
import chroma from "chroma-js";
import t from "typy";
import Collapsible from "react-collapsible";
/* eslint-enable */
import "./sass/main.scss";
import "../../../index.css";
import "./sidebarLayers.css";

//11-33 add handles for opacity control
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import Tooltip from "rc-tooltip";
import Slider from "rc-slider";

const config = window.config;

const Handle = Slider.Handle;

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps}  />
    </Tooltip>
  );
};

export default class sidebarLayers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      layers: [],
      layerStyle: {},
      fillOpacity: [],
    };

    this.handleSliderChange = this.handleSliderChange.bind(this);
	this.formatValue = this.formatValue.bind(this);
    
  }

   componentDidMount () {
    let layers = Object.values(config.layers).map((layer) => {
      let layerStyle = this.state.layerStyle;
      let fillOpacity =  this.state.fillOpacity;

      layerStyle[layer.name] = layer.style;
	  fillOpacity[layer.name] = layer.style.fillOpacity;
	  
      this.setState({layerStyle: layerStyle});
      this.setState({fillOpacity: fillOpacity});

      return layer;
    });

    layers = this.state.layers.concat(layers);
    this.setState({layers: layers});
  } 

	formatValue (layer, attribute, val) {
		let thisField = {}
		for (let i in layer.outFields){
			if (layer.outFields[i].name === attribute){
				thisField = layer.outFields[i];
				continue;
			}
		}
		if("format" in thisField){
			if(thisField.format === "decimals"){
				val = parseFloat(val).toFixed(thisField.decimals);
				val = val.toString();
			} else if (thisField.format === "date"){
				let d = new Date(val);
				val = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear() ;
			} else if (thisField.format === "url"){
				val = "<a href='" + val + "'>" + val + "</a>"
			} else if (thisField.format === "boolean"){
				if (val === true || val === 1 || val === "1" || val.toLowerCase() === "true" || val.toLowerCase() === "yes" || val.toLowerCase() === "y"){
					val = "Yes"
				} else {
					val = "No"
				}
			} else if (thisField.format === "percent"){
				if (typeof thisField.decimals !== "undefined" && thisField.decimals !== null){
					val = parseFloat(val).toFixed(thisField.decimals);
				}
				val = val.toString() + "%";
			} else if (thisField.format === "currency"){												
				let decimals = 2;
				if (typeof thisField.decimals !== "undefined" && thisField.decimals !== null){
					decimals = thisField.decimals;
				}
				val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(val);
			}
		}
		return val;
	}

  handleSliderChange(layer, value) {
    this.props.onLayerStyleUpdate(layer, "fillOpacity", value/100);
    this.props.onLayerStyleUpdate(layer, "opacity", value / 100);
  }

  handleZindexChange(layer, direction) {
    this.props.onLayerSetTop(layer);
  }

	render () {
		let opacityMarks = {}
		for(let i=0; i<=100; i+=20){
			opacityMarks[i] = i.toString() + "%";
		}
		let layerSelector = [];
		for (let i in config.layers){
			let layer = config.layers[i];

			if ( layer.url.includes("localhost") && this.props.includeLocalLayers === false ){
				continue;
			} else {
				let component = null;
				let symbology = null;
				if (typeof this.props.layerSymbol[layer.name] !== "undefined" && this.props.layerSymbol[layer.name] !== null ){
					let legend = [];
					let fieldLabel = null;
					if (this.props.layerSymbol[layer.name].type === "single"){
						let style = JSON.parse(JSON.stringify(this.props.layerStyle[layer.name]));
						legend = [{ 
							"symbol": style,
							"label": layer.name
						}]
					} else if ( this.props.layerSymbol[layer.name].type === "gradColor" ){
						legend = [];
						let symbolProps = this.props.layerSymbol[layer.name].properties;
						for (let i=0; i < symbolProps.colors.length; i+=1){
							let style = JSON.parse(JSON.stringify(this.props.layerStyle[layer.name]));
							style.fillColor = symbolProps.colors[i];
							if (typeof symbolProps.classLabels !== "undefined" && symbolProps.classLabels !== null){
								legend.push({
									"symbol": style,
									"label": this.formatValue(layer, symbolProps.fieldName, symbolProps.classLabels[i])
								});
							} else {
								let labelText = this.formatValue(layer, symbolProps.fieldName, symbolProps.breaks[i]).toString() + " - " + this.formatValue(layer, symbolProps.fieldName, symbolProps.breaks[i+1]).toString()
								legend.push({
									"symbol": style,
									"label": labelText
								});
							}
						}
						if(typeof symbolProps.suppressedDataValue !== "undefined" && symbolProps.suppressedDataValue !== null){
							let style = JSON.parse(JSON.stringify(this.props.layerStyle[layer.name]));
							style.fillColor = symbolProps.suppressedDataColor;
							legend.push({
								"symbol": style,
								"label": "(value suppressed)"
							});							
						}
						let style = JSON.parse(JSON.stringify(this.props.layerStyle[layer.name]));
						style.fillColor = symbolProps.noDataColor;
						legend.push({
							"symbol": style,
							"label": "(no data)"
						});												
						fieldLabel = <p className="fieldLabel">{symbolProps.fieldLabel}</p>
					} else if ( this.props.layerSymbol[layer.name].type === "categorical" ){
						legend = [];
						let symbolProps = this.props.layerSymbol[layer.name].properties;
						for(let category in symbolProps.colors){
							let style = JSON.parse(JSON.stringify(this.props.layerStyle[layer.name]));
							style.fillColor = symbolProps.colors[category];
							legend.push({
								"symbol": style,
								"label": category
							});
						};
						fieldLabel = <p className="fieldLabel">{symbolProps.fieldLabel}</p>
					}


					var thisLegend = [];
					for(let i in legend){
						let symbol = null;
						let style = {
							"fill":legend[i].symbol.fillColor,
							"fillOpacity":legend[i].symbol.fillOpacity,
							"opacity": legend[i].symbol.opacity,
							"stroke":legend[i].symbol.color,
							"strokeWidth":legend[i].symbol.weight
						};

						if (this.props.layerSymbol[layer.name].shape === "circle"){
							symbol = 
								<svg height="30" width="30">
									<circle cx="15" cy="15" r="4" style={style} />
								</svg>
						} else if (this.props.layerSymbol[layer.name].shape === "polygon"){
							symbol = 
								<svg height="30" width="30">
									<polygon points="2,7 20,7 28,23 2,23" style={style} />
								</svg>
						} else if (this.props.layerSymbol[layer.name].shape === "line") {
							style.stroke = style.fill;
							symbol = 
								<svg height="30" width="30">
									<line x1="2" y1="15" x2="28" y2="15" style={style} />
								</svg>
						}
						let row = <tr key = { Math.random() * 100 }><td>{symbol}</td><td>{legend[i].label}</td></tr>
							
						thisLegend.push(row)
					}

					symbology =
						<div>
							<h4>Symbology</h4>
							{fieldLabel}
							<table className="legend">
								<tbody>
									{thisLegend}
								</tbody>
							</table>
						</div>

				} // END if (typeof this.props.layerSymbol[layer.name].properties !== "undefined" ...

				component = 
			<Collapsible key = { Math.random() * 100 } trigger={layer.name}  open={this.props.visible[layer.name]} onOpen={() => this.props.onLayerToggle(layer.name)} onClose={() => this.props.onLayerToggle(layer.name)}>
						<div id={layer.name} className="layerContainer">
						<p>{ layer.description }</p>
						<h4>Source</h4>
						<p>{ layer.attribution }</p>
						{symbology}
						<h4>Opacity</h4>
						<Slider min={0} max={100} marks={opacityMarks} defaultValue={layer.style.fillOpacity*100} handle={handle} onAfterChange={(value)=>{this.handleSliderChange(layer.name, value);}}/>
						<h4>Ordering</h4>
						<button onClick={(e) => {this.handleZindexChange(layer.name, 0);}} >Promote</button>
						</div>
					</Collapsible>
					layerSelector.push(component);

			}; // END if/else ( layer.url.includes("localhost") && this.state.includeLocalLayers == false )
		}; // END for (let i in config.layers){
		return(
			<>
			<p>Click a layer name below to turn layers on and off.</p>
			<div className="layerGroupContainer">
				{layerSelector}
			</div>
			</>
		);
	}  // END render()
} // END export default class sidebarLayers