/* eslint-disable */
import React from "react";
/* eslint-enable */

import LogoOSU from "../../../assets/logo-osu.png";
import LogoCOTS from "../../../assets/logo-cots.jpg";
import LogoMightyCrow from "../../../assets/logo-mightycrow.png"; 

const sidebarHome = () => {
  return (
    <>
    <div>
      <h2>FOCAL Map</h2>
      <p>
      The Franklin County Opioid Crisis Activity Levels (FOCAL) Map is a tool to help Ohio State University (OSU) researchers and community partners identify patterns of opioid overdose events in space and time, and to better understand the social determinants and environmental factors that are associated with these patterns.
      </p>
	  <p>
	  This version of FOCAL Map can be viewed using <a href="https://www.google.com/chrome/">Chrome</a> and <a href="https://www.mozilla.org/en-US/firefox/new/">Firefox</a> web browsers.
	  </p>
      <hr />
      <h2>Features</h2>
      <p><b>Timely</b> Overdose event data is updated in near real-time</p>
      <p><b>Complete</b> FOCAL Map aggregates overdose event data from 23 local EMS agencies (current data shown for 14 agencies only).</p>
      <p><b>Comprehensive</b> FOCAL Map provides a broad set of reference data sets to better understand the context of overdose events.</p>
      <hr />
      <h2>Acknowledgements</h2>
      <p>
      FOCAL Map is funded by the <a href="https://oaa.osu.edu/opioid-innovation-fund">Provost's Opioid Innovation Fund</a> and a <a href="https://www.cdc.gov/drugoverdose/od2a/index.html">Centers for Disease Control Overdose Data to Action grant</a> administered by Franklin County Public Health.
      </p>
      <p>
      Geocoding services were provided in part by <a href="https://geoservices.tamu.edu">Texas A&M University GeoServices</a>.      
      </p>      
      <hr />
      <h2>Collaborators</h2>
      <p><img src={LogoOSU} alt="Logo of The Ohio State University"></img></p>
      <p>
        <a href="https://cph.osu.edu/">College of Public Health</a><br />
        <a href="https://cura.osu.edu/">Center for Urban and Regional Analysis</a><br />
        <a href="https://wexnermedical.osu.edu/">Wexner Medical Center</a><br />
		<a href="https://u.osu.edu/hopes/">Center for Health Outcomes and Policy Evaluation Studies</a>
      </p>
      <p><a href="https://www.centralohiotraumasystem.org/"><img src={LogoCOTS} alt="Logo of Central Ohio Trauma System"></img></a></p>
      <p><a href="https://mightycrow.com/"><img src={LogoMightyCrow} alt="Logo of Mighty Crow Media"></img></a></p> 
    </div>
    </>
  );
};

export default sidebarHome;
