import React from 'react'
// Can we merge this file with index.js?

// Can we change "SimpleExample" to something more meaningful?
import SimpleExample from './simple'

const App = () => (
    <SimpleExample />
)

export default App
