import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import React, {Component} from "react";
import Slider from "rc-slider";
import * as moment from 'moment';
import "../../../index.css";
import "./sidebarQuery.css";

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

export default class sidebarQuery extends Component {
  //same thing, we need include below lines of code to recieve props values from focalSideBar component
  constructor(props){
    super(props);

    this.state = { 
		name: { 
		  type: " LIKE ", 
		  value: "" 
		},
		address: {
		  type: " LIKE ",
		  value: ""
		},
		gender: {
		  type: "all"
		},
		services: {
		  type: " LIKE ",
		  value: ""
		},
		features: {
			pregnancy: false,
			veteran: false,
			ins_private: false,
			ins_medicaid: false,
			ins_medicare: false,
			ins_self: false,
			ins_slide: false,
			ins_finance: false,
			ins_free: false,
			ins_insurance: false,
			ages_juveniles: false,
			ages_adults: false,
			ages_older_adults: false
		}
    };
    this.nameTypeChange = this.nameTypeChange.bind(this);
    this.addressTypeChange = this.addressTypeChange.bind(this);
    this.nameValueChange = this.nameValueChange.bind(this);
    this.addressValueChange = this.addressValueChange.bind(this);
    this.genderChange = this.genderChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
	this.prepareMarks = this.prepareMarks.bind(this);
	this.formatDateTooltip = this.formatDateTooltip.bind(this);
	this.servicesValueChange = this.servicesValueChange.bind(this);
	this.featuresChange = this.featuresChange.bind(this);
  }
	
  nameTypeChange(e){
    let val = this.state;
    val.name.type = e.target.value;
    this.setState(val);
  }
  nameValueChange(e){
    let val = this.state;
    val.name.value = e.target.value;
    this.setState(val);
  }
  addressTypeChange(e){
    let val = this.state;
    val.address.type = e.target.value;
    this.setState(val);
  }
  addressValueChange(e){
    let val = this.state;
    val.address.value = e.target.value;
    this.setState(val);
  }
  genderChange(e){
    let val = this.state;
    val.gender.type = e.target.value;
    this.setState(val);
  }
  servicesValueChange(e){
    let val = this.state;
    val.services.value = e.target.value;
    this.setState(val);
  }
  featuresChange(e){
	let feature = e.target.value;
	let checked = e.target.checked;
	let val = this.state;
	if(checked){
		val.features[feature] = true;
	} else {
		val.features[feature] = false;
	}
    this.setState(val);
  }
  handleSubmit(e){
    e.preventDefault();
    this.props.providerChange(this.state);
  }

  prepareMarks(range, skip=1){
	  let marks = {};
	  if (range.length === 2){
		let min = range[0];
		let max = range[1];
		let step = Math.ceil((max - min)/5);
		let mark = min;
		while(mark < max){
			marks[mark] = mark;
			mark += step;
		}
		marks[max] = max;
	  } else {
		  for (let i=0; i < range.length; i+=skip){
			  marks[range[i]] = range[i];
		  }
	  }
	  return marks;
  }
  prepareDateMarks(range){
	  let marks = {};
		let timestamp = null;
		let minYear = parseInt(moment(range[0]).format("YYYY"));
		let maxYear = parseInt(moment(range[1]).format("YYYY"));
		let step = Math.ceil((maxYear - minYear)/5);
		let year = minYear;
		while(year < maxYear){
			timestamp = moment(year.toString(),"YYYY").valueOf();
			marks[timestamp] = year.toString();
			year += step;
		}
		timestamp = moment(year.toString(),"YYYY").valueOf();
		marks[timestamp] = year.toString();
	  return marks;
  }
  formatDateTooltip(value){
		let dateString = moment(value).format("MM/DD/YYYY");
		return `${dateString}`;
  }

  //Here is the place we use those props value, use this.props.xxx.xxx, here we need max, min, marks for census and zip object
  render(){

	let queryBlocks = [];

    if (typeof this.props.layerTime["Simulated overdose events"] !== 'undefined' && this.props.layerTime["Simulated overdose events"] !== null){
		let timeProps = this.props.layerTime["Simulated overdose events"].properties;
		let min = moment(timeProps.range[0]).valueOf();
		let max = moment(timeProps.range[1]).valueOf();
		let defaultInterval = [moment(timeProps.defaultInterval[0]).valueOf(), moment(timeProps.defaultInterval[1]).valueOf()]
		let marks = this.prepareDateMarks(timeProps.range);
		let component = 
			<div className="queryGroupContainer" key="odEvents">
				<h3>Overdose events</h3>
				<Range min={min} max={max} defaultValue={defaultInterval} marks={marks} onAfterChange={this.props.odEventsChange} tipFormatter={this.formatDateTooltip} />
			</div>
		
		queryBlocks.push(component)
	}

    if (typeof this.props.layerSymbol["Overdose rate by census tract (5 year)"] !== 'undefined' && this.props.layerSymbol["Overdose rate by census tract (5 year)"] !== null){
		let symbolProps = this.props.layerSymbol["Overdose rate by census tract (5 year)"].properties;
		let component = 
			<div className="queryGroupContainer" key="odTract">
				<h3>Census Tract</h3>
				<h4>Overdose rate, 5 year<span className="units"> (per 10k people)</span></h4>
				<Range min={symbolProps.range[0]} max={symbolProps.range[1]} defaultValue={[symbolProps.range[0], symbolProps.range[1]]} marks={this.prepareMarks(symbolProps.range)} tipFormatter={value => `${value}%`} onAfterChange={this.props.censusChange}/>
			</div>

		queryBlocks.push(component)
	} else {
		let component = 
			<div className="queryGroupContainer" key="odTract">
				<h3>Census Tract</h3>
				<h4>Overdose rate, 5 year<span className="units"> (per 10k people)</span></h4>
				<p>Data for this layer has not been loaded yet.  Turn on the layer in the Layers tab, then return here to use the filter.</p>
			</div>

		queryBlocks.push(component)		
	}

    if (typeof this.props.layerSymbol["Overdose rate by zipcode (2 year)"] !== 'undefined' && this.props.layerSymbol["Overdose rate by zipcode (2 year)"] !== null){
		let symbolProps = this.props.layerSymbol["Overdose rate by zipcode (2 year)"].properties;
		let component = 
			<div className="queryGroupContainer" key="odZip">
				<h3>Zip Code</h3>
				<h4>Overdose rate, 2 year<span className="units"> (per 10k people)</span></h4>
				<Range min={symbolProps.range[0]} max={symbolProps.range[1]} defaultValue={[symbolProps.range[0], symbolProps.range[1]]} marks={this.prepareMarks(symbolProps.range)} tipFormatter={value => `${value}%`} onAfterChange={this.props.zipChange}/>
			</div>

		queryBlocks.push(component)
	}  else {
		let component = 
			<div className="queryGroupContainer" key="odZip">
				<h3>Zip Code</h3>
				<h4>Overdose rate, 2 year<span className="units"> (per 10k people)</span></h4>
				<p>Data for this layer has not been loaded yet.  Turn on the layer in the Layers tab, then return here to use the filter.</p>
			</div>

		queryBlocks.push(component)		
	}

	if (typeof this.props.layerTime["Overdose Heatmap"] !== 'undefined' && this.props.layerTime["Overdose Heatmap"] !== null){
		let timeProps = this.props.layerTime["Overdose Heatmap"].properties;
		let component = 
			<div className="queryGroupContainer" key="od_heatmap">
				<h3>Overdose Heatmap</h3>
				<h4>Overdose likelihood rank<span className="units"> (percentile)</span></h4>
				<Slider min={timeProps.range[0]} max={timeProps.range[1]} defaultValue={timeProps.defaultInterval} marks={ this.prepareMarks(timeProps.intervals, 2) } tipFormatter={value => `${value}%`} onAfterChange={this.props.odHeatmapChange} step={1} included={false}/>
			</div>		

		queryBlocks.push(component)
	} else {
		let component = 
			<div className="queryGroupContainer" key="od_heatmap">
				<h3>Overdose Heatmap</h3>
				<p>Data for this layer has not been loaded yet.  Turn on the layer in the Layers tab, then return here to use the filter.</p>
			</div>	

		queryBlocks.push(component)		
	}
	let component = 
		<div className="queryGroupContainer">
		<form onSubmit={this.handleSubmit}>
		  <h3>Treatment Providers</h3>
		  <p><i>Treatment provider data is provided for informational purposes only and should not be used to refer patients.</i></p>
		  <label>
			<h4>Name:</h4>
			<select value={this.state.name.type} onChange={this.nameTypeChange}>
			  <option value=" LIKE ">Contains</option>
			  <option value=" = ">Equal To</option>
			</select>
			<input type="text" onChange={this.nameValueChange}></input>
		  </label>	
		  <label>
			<h4>Address:</h4>
			<p><select value={this.state.address.type} onChange={this.addressTypeChange}>
			  <option value=" LIKE ">Contains</option>
			  <option value=" = ">Equal To</option>
			</select>
			<input type="text" onChange={this.addressValueChange}></input></p>
		  </label>
		  <label>
			<h4>Services offered:</h4>
			<p>Contains: <input type="text" onChange={this.servicesValueChange}></input></p>
		  </label>
		  <label>
			<h4>Gender served:</h4>
			<p><select value={this.state.gender.type} onChange={this.genderChange}>
			  <option value="all">All</option>
			  <option value="male">Male only</option>
			  <option value="female">Female only</option>
			</select></p>
		  </label>
		  <label>	  
			<h4>Features:</h4>
			<p><input type="checkbox" id="ages_juveniles" name="ages_juveniles" value="ages_juveniles" onChange={this.featuresChange}/> Juveniles accepted?</p>
			<p><input type="checkbox" id="ages_older_adults" name="ages_older_adults" value="ages_older_adults" onChange={this.featuresChange} /> Older adults accepted?</p>
			<p><input type="checkbox" id="pregnancy" name="pregnancy" value="pregnancy" onChange={this.featuresChange} /> Pregnant patients accepted?</p>
			<p><input type="checkbox" id="veteran" name="veteran" value="veteran" onChange={this.featuresChange} /> Veterans only?</p>
			<p><input type="checkbox" id="ins_private" name="ins_private" value="ins_private" onChange={this.featuresChange} /> Private insurance accepted?</p>
			<p><input type="checkbox" id="ins_medicaid" name="ins_medicaid" value="ins_medicaid" onChange={this.featuresChange} /> Medicaid accepted?</p>
			<p><input type="checkbox" id="ins_medicare" name="ins_medicare" value="ins_medicare" onChange={this.featuresChange} /> Medicare accepted?</p>
			<p><input type="checkbox" id="ins_self" name="ins_self" value="ins_self" onChange={this.featuresChange} /> Self-insurance accepted?</p>
			<p><input type="checkbox" id="ins_slide" name="ins_slide" value="ins_slide" onChange={this.featuresChange} /> Sliding-scale pricing?</p>
			<p><input type="checkbox" id="ins_finance" name="ins_finance" value="ins_finance" onChange={this.featuresChange} /> Financing available?</p>
			<p><input type="checkbox" id="ins_free" name="ins_free" value="ins_free" onChange={this.featuresChange} /> Free services available?</p>
		  </label>
		  <input type="submit" value="Find Treatment Providers" />
		</form>
		</div>

	queryBlocks.push(component)			
    
	return(
      <div>
		{queryBlocks}
      </div>
    );
  }
}



