/* eslint-disable */
import React, { Component } from "react";
/* eslint-enable */

export default class sidebarLayers extends Component {

	constructor(props) {
		super(props);
	};

	render () {
		return (
			<div>
				<p>
				Click the button below to export FOCAL Map to a report that can be saved or printed.  The report will include the map as it is currently shown in the application, and a legend for each visible layer.  The report will appear in a new tab or window.  If the report fails to open, make sure you have allowed pop-ups for this page.
				</p>
				<button onClick={this.props.onExport} >Export map</button>				
			</div>
		);
	};
};

