/* eslint-disable */
import React, { Component } from "react";
import { Sidebar, Tab } from "react-leaflet-sidetabs";
import { FiHome, FiChevronRight, FiFilter, FiSettings, FiLayers, FiUpload, FiDownload } from "react-icons/fi";
import SidebarHome from "./SideBarContent/sidebarHome";
import SidebarLayers from "./SideBarContent/sidebarLayers";
import SidebarQuery from "./SideBarContent/sidebarQuery";
import SidebarExport from "./SideBarContent/sidebarExport";
import "../../index.css"
import "./focalSidebar.css";

/* eslint-enable */

export default class FocalSidebar extends Component {
  
	//When we want to use values that props from parent component we need to have below few lines of code
	constructor(props) {
		super(props);
		this.state = {
			collapsed: false,
			selected: "home",
		};
	}

	onClose() {
		this.setState({collapsed: true});
	}
	onOpen(id) {
		this.setState({
			collapsed: false,
			selected: id,
		});
	}

	/* Because this focalSideBar is a hub to control several few other components like sideBarHome, sidebarQuery etc.
	Values props from parent component will not being directly used in this component, 
	so we will futher props it down to childcomponent like sideBarQuery and SideBarLayers
	it works exact same as simple.js see line 50-59*/
	render() {

		return (
			<Sidebar id="sidebar" position="left" collapsed={this.state.collapsed} closeIcon={<FiChevronRight />} selected={this.state.selected} onOpen={this.onOpen.bind(this)} onClose={this.onClose.bind(this)}>
				<Tab id="home" header="Home" icon={<FiHome />} style={{overflowY: "auto"}}>
					<SidebarHome />
				</Tab>
				<Tab id="query" header="Query" icon={<FiFilter />} style={{overflowY: "auto"}}>
					<SidebarQuery 
						layerData={this.props.layerData} 
						layerSymbol={this.props.layerSymbol} 
						layerTime={this.props.layerTime}
						zipChange={this.props.zipChange} 
						censusChange={this.props.censusChange} 
						odEventsChange={this.props.odEventsChange} 
						providerChange={this.props.providerChange}
						odHeatmapChange={this.props.odHeatmapChange}
						includeLocalLayers={this.props.includeLocalLayers}
					/>
				</Tab>
				<Tab id="layers" header="Layers" icon={<FiLayers />} style={{overflowY: "auto"}}>
					<SidebarLayers 
						layerData={this.props.layerData}
						layerStyle={this.props.layerStyle}
						layerSymbol={this.props.layerSymbol}
						zipChange={this.props.zipChange}
						censusChange={this.props.censusChange}
						providerChange={this.props.providerChange}
						visible={this.props.visible}
						onLayerStyleUpdate={this.props.onLayerStyleUpdate}
						onLayerSetTop={this.props.onLayerSetTop}
						onLayerToggle={this.props.onLayerToggle}
						includeLocalLayers={this.props.includeLocalLayers}
					/>
				</Tab>
			</Sidebar>
		); // END return()
  } // END render()
} // END export default class FocalSidebar

/*
				<Tab id="export" header="Export" icon={<FiDownload />} style={{overflowY: "auto"}}>				
					<SidebarExport 
						onExport={this.props.onExport}
					/>
				</Tab>
*/
