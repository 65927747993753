/* eslint-disable */
import React, { Fragment, Component } from "react";
import { Map, GeoJSON, TileLayer, LayersControl, ZoomControl } from "react-leaflet";
import axios from "axios";
import chroma from "chroma-js"
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import blankTile from "../assets/blank_tile.png";
import L from "leaflet";
import Choropleth from "react-leaflet-choropleth";
import FocalSidebar from "./SideBarControl/focalSidebar";
import * as templatedata from "./Templates/template.json"
import "../index.css";
//import loadingSymbol from "../assets/loading.gif";
import { withRouter } from "react-router-dom";
import nunjucks from 'nunjucks';

//const {BaseLayer, Overlay} = LayersControl;
/* eslint-enable */

const config = window.config;

class SimpleExample extends Component {

    constructor(props) {
        super(props);

        this.state = {
			includeLocalLayers: false,
			localLayersURL: "http://localhost/arcgis/rest/services?f=pjson",
            topLayerName: '',
            urlLayers: '',
            urlZoom: 11,
            urlLat: 39.972647446014925,
            urlLng: -83.01457554101945,
            lat: 39.972647446014925,
            lng: -83.01457554101945,
            zoom: 11,
            layerData: {},
            queryData: {},
			urls:{},
			layerTime: {},
            layerStyle: {},
			layerSymbol: {},
            layers: [],
            queryResults: [],
            visible: {},
            url: new URL(window.location)
        };

        this.getPointStyle = this.getPointStyle.bind(this);
        this.handleZipChange = this.handleZipChange.bind(this);
        this.handleCensusChange = this.handleCensusChange.bind(this);
        this.handleOdEventsChange = this.handleOdEventsChange.bind(this);
        this.handleOdHeatmapChange = this.handleOdHeatmapChange.bind(this);
        this.handleProviderChange = this.handleProviderChange.bind(this);
        this.handleLayerToggle = this.handleLayerToggle.bind(this);
		this.handleAcknowledgement = this.handleAcknowledgement.bind(this);
        this.onMove = this.onMove.bind(this)
        this.verifyDataReady = this.verifyDataReady.bind(this);
		this.setStyle = this.setStyle.bind(this);
        this.setGradColorStyle = this.setGradColorStyle.bind(this);
		this.setCategoricalColorStyle = this.setCategoricalColorStyle.bind(this);
		this.handleLayerStyleUpdate = this.handleLayerStyleUpdate.bind(this);
        this.handleLayerSetTop = this.handleLayerSetTop.bind(this);
        this.handleLayerSetBottom = this.handleLayerSetBottom.bind(this);
        this.handleExport = this.handleExport.bind(this);
		this.formatValue = this.formatValue.bind(this);
		this.initialFetchLayerData = this.initialFetchLayerData.bind(this);
		this.choropleth = null;
		this.filterOpen = "<ogc:Filter>";
		this.filterAndOpen = "<fes:And>";
		this.filterAndClose = "</fes:And>";
		this.filterClose = "</ogc:Filter>";
		this.initialMountInProgress = true;
		this.layerToggle = false;

    }

	verifyDataReady(){
		let layerData = this.state.layerData 
		let isVisible = this.state.visible
		let ready = true
		if(this.initialMountInProgress){
			return false
		}
		for(let name in isVisible){
			if(isVisible[name]){
				if((!Object(layerData).hasOwnProperty(name)) || (layerData[name] === null)){
					ready = false
					break
				}
			}
		}
		return ready
	}

	setGradColorStyle(layerName, geoJson, symbolProps){
		let data = geoJson.features.map((feature) => {
		    if (feature.properties[symbolProps.fieldName] === null || feature.properties[symbolProps.fieldName] === undefined){
		        return null;
			} else {
		        return feature.properties[symbolProps.fieldName];
		    }
		});

		let c = null;
		if (symbolProps.classType.toLowerCase() === "m" || symbolProps.classType.toLowerCase() === "manual"){
			// Determine class breaks
			symbolProps.setFillColor = chroma.scale(symbolProps.colorScale).classes(symbolProps.breaks).nodata(symbolProps.noDataColor);
		} else {
			// Temporarily convert suppressed symbol to null so it does not affect class break determination
			let dataTemp = [];
			if(typeof symbolProps.suppressedDataValue !== "undefined" && symbolProps.suppressedDataValue !== null){
				dataTemp = data.map(function (x){
					if(x === symbolProps.suppressedDataValue){
						return null
					} else {
						return x
					}
				})
				data = dataTemp;
			}

			// Determine class breaks
			symbolProps['breaks'] = chroma.limits(data, symbolProps.classType, symbolProps.nClasses)
		}		

		// Assign colors to classes
		symbolProps.setFillColor = chroma.scale(symbolProps.colorScale).classes(symbolProps.breaks).nodata(symbolProps.noDataColor)

		symbolProps['colors'] = [];
		// Assign a color to each class, using the minimum class value as the index
		for(let i = 0; i < symbolProps.breaks.length-1; i+=1){
			symbolProps.colors.push(symbolProps.setFillColor(symbolProps.breaks[i]).hex())
		}
		//if(typeof symbolProps.suppressedDataValue !== "undefined" && symbolProps.suppressedDataValue !== null){
		//	symbolProps.colors.push(symbolProps.setFillColor(symbolProps.breaks[i]).hex())
		//}
		symbolProps['range'] = [Math.floor(Math.min.apply(null, data)), Math.ceil(Math.max.apply(null, data))]
		return symbolProps
	}

	setCategoricalColorStyle(layerName, geoJson, fieldName, fieldLabel, colorScale){
		let data = geoJson.features.map((feature) => {
		    if (feature.properties[fieldName] === null || feature.properties[fieldName] === undefined){
		        return null;
			} else {
		        return feature.properties[fieldName];
		    }
		});
		let uniqueValues = [...new Set(data)];
		let c = chroma.scale(colorScale).colors(uniqueValues.length);
		let colors = {};
		for(let i = 0; i < uniqueValues.length; i+=1){
			colors[uniqueValues[i]] = c[i];
		}
		let f = function(value){
			return colors[value];
		}
		return {"colors": colors, "setFillColor": f, "fieldLabel": fieldLabel}
	}

	handleAcknowledgement(){
		var intended_use = document.getElementById("intended_use");
		intended_use.style.visibility = "hidden";
		intended_use.style.height = "0px";		
	}
	

    setStyle(feature, name) {
        let q = this.state.queryData;
 		const { layerStyle } = this.state;
        const currentStyle = layerStyle[name];
        let sty = config.layers[name].style;
        sty = {
            "fillColor": currentStyle ? currentStyle.fillColor : config.layers[name].style.fillColor,
            "fillOpacity": currentStyle ? currentStyle.fillOpacity : config.layers[name].style.fillOpacity,
            "weight": currentStyle ? currentStyle.weight : config.layers[name].style.weight,
            "opacity": currentStyle ? currentStyle.opacity : config.layers[name].style.opacity,
            "color": currentStyle ? currentStyle.color : config.layers[name].style.color
        };
        if (q[name] !== undefined) {
            if (q[name].objectIds == undefined) {
                sty.fillOpacity = 0;
                sty.opacity = 0;
            } else if (!(q[name].objectIds.includes(feature.properties[q[name].objectIdFieldName]))) {
                sty.fillOpacity = 0;
                sty.opacity = 0;
            }
        }
        return sty;
    }

	formatValue (layer, attribute, val) {
		let thisField = {}
		for (let i in layer.outFields){
			if (layer.outFields[i].name === attribute){
				thisField = layer.outFields[i];
				continue;
			}
		}

		let symbolProps = this.state.layerSymbol[layer.name].properties;
		
		if(typeof symbolProps.suppressedDataValue !== "undefined" && symbolProps.suppressedDataValue != null && val == symbolProps.suppressedDataValue){
			val = "(value suppressed)";
		} else if("format" in thisField){
			if(thisField.format === "decimals"){
				val = parseFloat(val).toFixed(thisField.decimals);
				val = val.toString();
			} else if (thisField.format === "hidden"){
				val = null;
			} else if (thisField.format === "date"){
				let d = new Date(val);
				val = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear() ;
			} else if (thisField.format === "url"){
				val = "<a href='" + val + "' target='_blank'>" + val + "</a>"
			} else if (thisField.format === "boolean"){
				let affirmative = null
				if(typeof val === "number"){
					if (val === 1){
						affirmative = true
					} else if (val === 0){
						affirmative = false
					}
				} else if (typeof val === "string"){
					if (val === "1" || val.toLowerCase() === "true" || val.toLowerCase() === "yes" || val.toLowerCase() === "y"){
						affirmative = true
					} else if (val === "0" || val.toLowerCase() === "false" || val.toLowerCase() === "no" || val.toLowerCase() === "n"){
						affirmative = false
					}
				} else if (typeof val === "boolean"){
					if (val){
						affirmative = true
					} else {
						affirmative = false
					}
				}

				if(affirmative === true){
					val = "Yes"
				} else if(affirmative === false) {
					val = "No"
				} else {
					val = "Unknown"
				}

			} else if (thisField.format === "percent"){
				if (typeof thisField.decimals !== "undefined" && thisField.decimals !== null){
					val = parseFloat(val).toFixed(thisField.decimals);
				}
				val = val.toString() + "%";
			} else if (thisField.format === "currency"){												
				let decimals = 2;
				if (typeof thisField.decimals !== "undefined" && thisField.decimals !== null){
					decimals = thisField.decimals;
				}
				val = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(val);
			}
		}
		return val;
	}

    handleLayerToggle(name) {
        let isVisible = this.state.visible;
        let layers = this.state.layers
		
		isVisible[name] = !isVisible[name];
	
		this.layerToggle = true

        this.setState({
			visible: isVisible,
        })

    }

	handleExport(){
		var OpenWindow = window.open("")
		OpenWindow.document.title = "FOCAL Map Report"

		let exportContent = "";
		exportContent += "<html>";
		exportContent += "<head>";
		exportContent += "<link rel='stylesheet' type='text/css' href='https://www.osu.edu/assets/fonts/webfonts.css'>";
		exportContent += "<style>";
		exportContent += "	body {"
		exportContent += "		font-family:'proximanova',Arial,sans-serif;"
		exportContent += "	}"
		exportContent += "</style>";
		exportContent += "</head>";
		exportContent += "<body>";
		exportContent += "<h1 style='text-align:center'>FOCAL Map Report</h1>";
		exportContent += "<p>This document was created by the Franklin County Opioid Crisis Activity Levels (FOCAL) Map, a tool to help OSU researchers and community partners identify patterns of opioid overdose events in space and time, and to better understand the social determinants and environmental factors that are associated with these patterns. FOCAL Map is funded by the <a href='https://oaa.osu.edu/opioid-innovation-fund'>Provost's Opioid Innovation Fund</a>, and is a collaboration between the <a href='https://cph.osu.edu/'>OSU College of Public Health</a>, the <a href='https://cura.osu.edu'>Center for Urban and Regional Analysis</a>, the <a href='https://wexnermedical.osu.edu/'>Wexner Medical Center</a>, the <a href='https://www.centralohiotraumasystem.org/'>Central Ohio Trauma System</a>, and <a href='https://mightycrow.com/'>Mighty Crow</a>.</p>"
		exportContent += "<div id='map' style='width:100%;text-align:center'></div>";
		exportContent += "<div id='legend' style='width:100%;justify-content:center;display:-webkit-flex;-webkit-flex-wrap:wrap;display:flex;flex-wrap:wrap;'></div>";		
		exportContent += "</body>";
		exportContent += "</html>";
	
		let canvasWidth = window.innerWidth * 0.95;
	
		OpenWindow.document.body.innerHTML = exportContent;
		let mapExport = OpenWindow.document.getElementById("map");
		let legendExport = OpenWindow.document.getElementById("legend");
	
		//let sidebar = document.getElementById("sidebar");
		//sidebar.style.visibility = "hidden";
		html2canvas(document.querySelector(".leaflet-container"),{
			width: canvasWidth,
			allowTaint: true,
			imageTimeout: 3000,
			ignoreElements: (element) => { 
				// leaflet-control-container exclusion eliminates zoom control (etc.)
				// IMG/focal-data exclusion eliminates tiles originating from focal-data server (current implementation causes export to fail)
				if(element.className === "leaflet-control-container" || element.id === "sidebar"){ // || (element.nodeName === "IMG" && element.src.includes("focal-data"))){
					return true;
				} else {
					return false;
				}
			}
		}).then(canvas => {
			mapExport.appendChild(canvas);
		});
		//sidebar.style.visibility = "visible";		

		let layers = document.querySelectorAll("div.layerContainer")
		let layerContainer = null;
		let exports = []
		
		for (let i in layers){
			if (typeof layers[i] === "object" && this.state.visible[layers[i].id] === true && layers[i].id){
				layerContainer = OpenWindow.document.createElement("div");
				layerContainer.id = layers[i].id
				layerContainer.style.maxHeight = "300px";
				layerContainer.style.minWidth = "300px";
				layerContainer.style.overflowY = "auto";
				layerContainer.style.padding = "30px";
				layerContainer.style.margin = "10px";
				layerContainer.style.border = "1px solid grey";

				let layerName = OpenWindow.document.createElement("span");
				layerName.style.fontSize = "1.5em"
				layerName.innerHTML = "<strong>" + layers[i].id + "</strong>";
				let fieldLabel = layers[i].querySelector("p.fieldLabel");
				let legend = layers[i].querySelector("table.legend");

				layerContainer.appendChild(layerName);
				if (fieldLabel !== null){
					layerContainer.appendChild(fieldLabel);	
				}
				layerContainer.appendChild(legend);
		
				exports.push(layerContainer)
			}
		}

		for (let i in exports){
			legendExport.appendChild(exports[i]);
		}

	}

    handleLayerSetTop(name) {
		// Not finished.
		this.setState({ topLayerName: name });
		let aLayers = this.state.layers;
		let elem = aLayers.filter((elem) => (elem.name === name))[0];
		let index = aLayers.indexOf(elem);
		aLayers.splice(index, 1)
		aLayers.push(elem)
		this.setState({ layers: aLayers })
    }

    handleLayerSetBottom(name) {
		let aLayers = this.state.layers;
		let elem = aLayers.filter((elem) => (elem.name === name))[0];
		let index = aLayers.indexOf(elem);
		aLayers.splice(index, 1)
		aLayers.unshift(elem)
		this.setState({ layers: aLayers })
    }

    /*This handleProviderChange implement the query serch function for treatment provider
    In React framework variable name start with handle always is define as a function*/
    handleProviderChange(query) {
        let layer = config.layers["Treatment providers"];
        let query_url = layer.url + "/query?f=geojson&geometryType=esriGeometryEnvelope&geometryPrecision=4&Geometry=" + layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax + "&outFields=";

        for (let idx = 0; idx < layer.outFields.length; idx++) {
            if (idx === 0)
                query_url += layer.outFields[idx].name;
            else
                query_url += "," + layer.outFields[idx].name;

        }
        query_url += "&where=";
        let query_params = [];
        if (query.address.value !== "") {
            if (query.address.type === " LIKE ") {
				query_params.push("lower(address)" + query.address.type + "'%25" + query.address.value.toLowerCase() + "%25'");
            } else {
                query_params.push("address" + query.address.type + "'" + query.address.value + "'");
            }
        }

        if (query.name.value !== "") {
            if (query.name.type === " LIKE ") {
                query_params.push("lower(name)" + query.name.type + "'%25" + query.name.value.toLowerCase() + "%25'");
            } else {
                query_params.push("name" + query.name.type + "'" + query.name.value + "'");
            }
        }
		
        if (query.services.value !== "") {
            query_params.push("lower(services)" + " LIKE " + "'%25" + query.services.value.toLowerCase() + "%25'");
        }		

        if (query.gender.type === "male") {
            query_params.push("gender = 'male'");
        } else if (query.gender.type === "female") {
            query_params.push("gender = 'female'");
        }

		for(let feature in query.features){
			if(query.features[feature]){
				query_params.push(feature + "=1")
			}
		}

        query_url += query_params.join(" AND ");

		//console.log('Rest: '+query_url)

		//WFS Url

		// let wfs_url = layer.wfs_url;

		// wfs_url += "&typename="+layer.wfs_typename;

		// let filters = [];		
		// let coord = layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax;
		// filters.push(nunjucks.renderString(templatedata.bbox , {coord : coord}));

		// if (query.address.value !== "") {			
            // if (query.address.type === " LIKE ") {
            	// filters.push(nunjucks.renderString(templatedata.like , {property : "ADDR" , value : "*"+query.address.value+"*"}));				
            // } else {
            	// filters.push(nunjucks.renderString(templatedata.equals , {property : "ADDR" , value : query.address.value}));                
            // }
        // }

        // if (query.name.value !== "") {        	
            // if (query.name.type === " LIKE ") {
            	// filters.push(nunjucks.renderString(templatedata.like , {property : "NAME" , value : "*"+query.name.value+"*"}));				                
            // } else {
                // filters.push(nunjucks.renderString(templatedata.equals , {property : "NAME" , value : query.name.value}));                
            // }
        // }

        // if (query.gender.type === "male") {        	
            // filters.push(nunjucks.renderString(templatedata.equals , {property : "MALE" , value : 1}));                
            // filters.push(nunjucks.renderString(templatedata.notequals , {property : "FEMALE" , value : 1}));                
        // } else if (query.gender.type === "female") {        	
            // filters.push(nunjucks.renderString(templatedata.equals , {property : "FEMALE" , value : 1}));                
            // filters.push(nunjucks.renderString(templatedata.notequals , {property : "MALE" , value : 1}));
        // }

        // if (query.services.abuse) {        	
        	// filters.push(nunjucks.renderString(templatedata.equals , {property : "S01" , value : 1}));                            
        // }
        // if (query.services.mental) {        	
        	// filters.push(nunjucks.renderString(templatedata.equals , {property : "S02" , value : 1}));                            
        // }
        // if (query.services.mix) {            
        	// filters.push(nunjucks.renderString(templatedata.equals , {property : "S03" , value : 1}));                            
        // }
        // if (query.services.general) {            
        	// filters.push(nunjucks.renderString(templatedata.equals , {property : "S04" , value : 1}));                            
        // }
        
        // let result = "";

        // if(filters.length > 1)
        // {
        	// result = this.filterAndOpen;         	
        // }

        // for(let i = 0 ; i < filters.length ; i++)
    	// {
    		// result += filters[i];
    	// } 

        // if(filters.length)
        	// result += this.filterAndClose;

        // result = this.filterOpen + result + this.filterClose;

        // wfs_url += "&filter="+result;
        //console.log("WFS : "+wfs_url);

        let final_url = "";        
        // if(layer.service_type === "WFS")
        // {
        	// final_url = wfs_url;
        // }
        // else
        // {
        	final_url = query_url;
        // }        
        console.log("Final : "+final_url);
        //console.log("Type : "+layer.service_type);
        //In React then() is to return a promise after an asynchronous being excuted, catch(err) is to get the error once the asynchronous task gose wrong
        axios.get(final_url)
        .then((res) => {
            let data = this.state.layerData;
            if (res.data.features.length === 0) {
                console.log("Your search returned no results.");
				alert("Your search returned no results.");
            } else {
                data["Treatment providers"] = res.data;
                let isVisible = this.state.visible;
                isVisible["Treatment providers"] = true;
                this.setState({
                    layerData: data,
                    visible: isVisible
                });
            }
        })
        .catch((err) => {
            console.log("HTTP request failed with error: ", err);
        });
    }

    //This handleCensusChange implement the query serch function for overdose rate by tract
    handleCensusChange(value) {
		let min = this.state.layerSymbol["Overdose rate by census tract (5 year)"].properties.range[0];
		let max = this.state.layerSymbol["Overdose rate by census tract (5 year)"].properties.range[1];
        if (value[0] === min && value[1] === max) {
            let isVisible = this.state.visible;
            isVisible["Overdose rate by zipcode (2 year)"] = false;
            isVisible["Overdose rate by census tract (5 year)"] = true;
            this.setState({
                queryData: {},
                visible: isVisible
            });
        } else {
            let layer = config.layers["Overdose rate by census tract (5 year)"];
            //ARCGIS REST URL
            let query_url = layer.url + "/query?returnIdsOnly=true&f=geojson&geometryType=esriGeometryEnvelope&geometryPrecision=4&Geometry=" + layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax + "&outFields=";
            for (let idx = 0; idx < layer.outFields.length; idx++) {
                if (idx === 0)
                    query_url += layer.outFields[idx].name;
                else
                    query_url += "," + layer.outFields[idx].name;

            }
            query_url += `&where=rate+between+${value[0]}+AND+${value[1]}`;
			//Prepare WFS URL
			let wfs_url = layer.wfs_url+"&typename="+layer.wfs_typename;
			if(layer.type === "GeoJSON")
			{
				wfs_url += "&outputformat=GEOJSON";
			}
			
			let filters = [];

			let coord = layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax;
			//bboxFilter = "<ogc:BBOX><ogc:PropertyName>Shape</ogc:PropertyName><gml:Box srsName=\"urn:x-ogc:def:crs:EPSG:3857\"><gml:coordinates>"+coord+"</gml:coordinates></gml:Box></ogc:BBOX>"
			filters.push(nunjucks.renderString(templatedata.bbox , {coord : coord}));						

			//let overdoseFilter = "<fes:PropertyIsGreaterThanOrEqualTo><fes:Literal>overdose_rate</fes:Literal><fes:Literal>"+value[0]+"</fes:Literal></fes:PropertyIsGreaterThanOrEqualTo><fes:PropertyIsLessThanOrEqualTo><fes:Literal>overdose_rate</fes:Literal><fes:Literal>"+value[1]+"</fes:Literal></fes:PropertyIsLessThanOrEqualTo>"
			filters.push(nunjucks.renderString(templatedata.between , {field : "rate" , greater : value[0] , lesser : value[1]}));			
			
			let filterVal = this.filterOpen + this.filterAndOpen; 
			for(let i = 0 ; i < filters.length ; i++)
			{
				filterVal += filters[i];
			}
			filterVal += this.filterAndClose + this.filterClose;
			wfs_url += "&filter="+filterVal;
			// wfs_url += "&PropertyName=ObjectID";
			//console.log("WFS : "+wfs_url);			
			//console.log("REST : "+query_url);	

			let final_url = "";        
	        if(layer.service_type === "WFS")
	        {
	        	final_url = wfs_url;
	        }
	        else
	        {
	        	final_url = query_url;
	        }        
	        //console.log("Final : "+final_url);
	        //console.log("Type : "+layer.service_type);		
            axios.get(final_url)
            .then((res) => {            
				let resp = res.data.objectIds;
				let objectIds = [];
            	// console.log(typeof res.data);
            	// let jsonRes = JSON.parse(res.data);
            	// console.log(jsonRes);
            	// console.log(typeof res.data);
            	resp.forEach(function(item){
            		objectIds.push(item);
            	});
            	//console.log(objectIds);
            	let respJson = {"objectIdFieldName": "OBJECTID", "objectIds": objectIds};
            	//console.log(respJson);
            	//console.log(typeof respJson);
                let data = this.state.queryData;
                if (objectIds === null || objectIds.length === 0) {
                    data["Overdose rate by census tract (5 year)"] = {};
                } else {
                    data["Overdose rate by census tract (5 year)"] = respJson;
                }
                let isVisible = this.state.visible;
                isVisible["Overdose rate by zipcode (2 year)"] = false;
                isVisible["Overdose rate by census tract (5 year)"] = true;
                this.setState({
                    queryData: data,
                    visible: isVisible
                });
            })
            .catch((err) => {
                console.log("Request failed with error: ", err);
            });
        }
    }

    //This handleZipChange implement the query serch function for overdose rate by zipcode
    handleZipChange(value) {
		let min = this.state.layerSymbol["Overdose rate by zipcode (2 year)"].properties.range[0];
		let max = this.state.layerSymbol["Overdose rate by zipcode (2 year)"].properties.range[1];
        if (value[0] === min && value[1] === max) {
            let isVisible = this.state.visible;
            isVisible["Overdose rate by zipcode (2 year)"] = true;
            isVisible["Overdose rate by census tract (5 year)"] = false;
            this.setState({
                queryData: {},
                visible: isVisible
            });
        } else {
            let layer = config.layers["Overdose rate by zipcode (2 year)"];
            let query_url = layer.url + "/query?returnIdsOnly=true&f=geojson&geometryType=esriGeometryEnvelope&geometryPrecision=4&Geometry=" + layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax + "&outFields=";
            for (let idx = 0; idx < layer.outFields.length; idx++) {
                if (idx === 0)
                    query_url += layer.outFields[idx].name;
                else
                    query_url += "," + layer.outFields[idx].name;

            }
            query_url += `&where=rate+between+${value[0]}+AND+${value[1]}`;
			console.log(query_url) 
			//WFS URL
			let wfs_url = layer.wfs_url+"&typename="+layer.wfs_typename;
			if(layer.type === "GeoJSON")
			{
				wfs_url += "&outputformat=GEOJSON";
			}
			
			let filters = [];			
			
			let coord = layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax;
			//bboxFilter = "<ogc:BBOX><ogc:PropertyName>Shape</ogc:PropertyName><gml:Box srsName=\"urn:x-ogc:def:crs:EPSG:3857\"><gml:coordinates>"+coord+"</gml:coordinates></gml:Box></ogc:BBOX>"
			filters.push(nunjucks.renderString(templatedata.bbox , {coord : coord}));			
			
			//let overdoseFilter = "<fes:PropertyIsGreaterThanOrEqualTo><fes:Literal>overdose_rate</fes:Literal><fes:Literal>"+value[0]+"</fes:Literal></fes:PropertyIsGreaterThanOrEqualTo><fes:PropertyIsLessThanOrEqualTo><fes:Literal>overdose_rate</fes:Literal><fes:Literal>"+value[1]+"</fes:Literal></fes:PropertyIsLessThanOrEqualTo>"
			filters.push(nunjucks.renderString(templatedata.between , {field : "rate" , greater : value[0] , lesser : value[1]}));			
			
			let filterVal = this.filterOpen + this.filterAndOpen; 
			for(let i = 0 ; i < filters.length ; i++)
			{
				filterVal += filters[i];
			}
			filterVal += this.filterAndClose + this.filterClose;
			wfs_url += "&filter="+filterVal;

			//console.log("REST : "+query_url);
			//console.log("WFS : "+wfs_url);
			let final_url = "";        
	        if(layer.service_type === "WFS")
	        {
	        	final_url = wfs_url;
	        }
	        else
	        {
	        	final_url = query_url;
	        }        
	        //console.log("Type : "+layer.service_type);
	        console.log("Final : "+final_url);	        
			axios.get(final_url)
            .then((res) => {
                let data = this.state.queryData;
                let resp = res.data.objectIds;
            	let objectIds = [];
            	// console.log(typeof res.data);
            	// console.log(typeof res.data);
            	resp.forEach(function(item){
            		objectIds.push(item);
            	});
            	let respJson = {"objectIdFieldName": "OBJECTID", "objectIds": objectIds};
            	//console.log(respJson);
            	//console.log(typeof respJson);
                if (objectIds === null || objectIds.length === 0) {
                    data["Overdose rate by zipcode (2 year)"] = {};
                } else {
                    data["Overdose rate by zipcode (2 year)"] = respJson;
                }
                let isVisible = this.state.visible;
                isVisible["Overdose rate by zipcode (2 year)"] = true;
                isVisible["Overdose rate by census tract (5 year)"] = false;
                this.setState({
                    queryData: data,
                    visible: isVisible
                });
            })
            .catch((err) => {
                console.log("Request failed with error: ", err);
            });
        }
    }

    //This handleOdEventsChange implements the query search function for simulated overdose events
    handleOdEventsChange(value) {
		let layer = config.layers["Simulated overdose events"];
        let query_url = layer.url + "/query?f=geojson&geometryType=esriGeometryEnvelope&geometryPrecision=4&Geometry=" + layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax + "&outFields=";
        for (let idx = 0; idx < layer.outFields.length; idx++) {
            if (idx === 0)
                query_url += layer.outFields[idx].name;
            else
                query_url += "," + layer.outFields[idx].name;

        }
        query_url += "&where=DATE > DATE '" + moment(value[0]).format("YYYY-MM-DD 00:00:00") + "' AND DATE < DATE '" + moment(value[1]).format("YYYY-MM-DD 23:59:59") + "'";
		
        //WFS URL - Simulate Overdose events is a localhost service

        /*let wfs_url = layer.wfs_url+"&typename="+layer.wfs_typename;
		if(layer.type === "GeoJSON")
		{
			wfs_url += "&outputformat=GEOJSON";
		}
		
		let filters = [];			
		
		let coord = layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax;
		//bboxFilter = "<ogc:BBOX><ogc:PropertyName>Shape</ogc:PropertyName><gml:Box srsName=\"urn:x-ogc:def:crs:EPSG:3857\"><gml:coordinates>"+coord+"</gml:coordinates></gml:Box></ogc:BBOX>"
		filters.push(nunjucks.renderString(templatedata.bbox , {coord : coord}));			

		let fromDate = moment(value[0]).format("YYYY-MM-DD 00:00:00");
		let toDate = moment(value[1]).format("YYYY-MM-DD 23:59:59");

		filters.push(nunjucks.renderString(templatedata.between , {field : 'DATE' , greater : fromDate , lesser : toDate}));

		let filterVal = this.filterOpen + this.filterAndOpen; 
		for(let i = 0 ; i < filters.length ; i++)
		{
			filterVal += filters[i];
		}
		filterVal += this.filterAndClose + this.filterClose;
		wfs_url += "&filter="+filterVal;*/

        //In React then() is to return a promise after an asynchronous being excuted, catch(err) is to get the error once the asynchronous task gose wrong
        axios.get(query_url)
        .then((res) => {
            let data = this.state.layerData;
            if (res.data.features.length === 0) {
                console.log("Your search returned no results!");
            } else {
                data["Simulated overdose events"] = res.data;
                let isVisible = this.state.visible;
                isVisible["Simulated overdose events"] = true;
                this.setState({
                    layerData: data,
                    visible: isVisible
                });
            }
        })
        .catch((err) => {
            console.log("HTTP request failed with error: ", err);
        });
    }


    handleOdHeatmapChange(value) {
        let layer = config.layers["Overdose Heatmap"];
        let query_url = layer.url + "/query?f=geojson&geometryType=esriGeometryEnvelope&geometryPrecision=4&Geometry=" + layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax + "&outFields=";
        for (let idx = 0; idx < layer.outFields.length; idx++) {
            if (idx === 0)
                query_url += layer.outFields[idx].name;
            else
                query_url += "," + layer.outFields[idx].name;

        }
        query_url += "&where=DATE >= DATE '" + moment(value + "-01-01 00:00:00").format("YYYY-MM-DD HH:mm:ss") + "' AND DATE <= DATE '" + moment(value + "-12-31 23:59:59").format("YYYY-MM-DD HH:mm:ss") + "'";
        //console.log("REST : "+query_url);
        //WFS URL
        let wfs_url = layer.wfs_url + "&typename="+layer.wfs_typename;
        if(layer.type === "GeoJSON")
        {
        	wfs_url += "&outputformat=GEOJSON";
        }
        
		let bboxFilter = "";
		if(typeof layer.extent != undefined)
		{
			let coordStr = layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax;
			//bboxFilter = "<ogc:BBOX><ogc:PropertyName>Shape</ogc:PropertyName><gml:Box srsName=\"urn:x-ogc:def:crs:EPSG:3857\"><gml:coordinates>"+coord+"</gml:coordinates></gml:Box></ogc:BBOX>"
			bboxFilter = nunjucks.renderString(templatedata.bbox , {coord : coordStr});			 
		}

		let fromDate = moment(value + "-01-01 00:00:00").format("YYYY-MM-DD HH:mm:ss");
		let toDate = moment(value + "-12-31 23:59:59").format("YYYY-MM-DD HH:mm:ss");

		// let dateFilter = "<fes:PropertyIsGreaterThanOrEqualTo>"+
		// 		"<fes:Literal>DATE</fes:Literal>"+
		// 		"<fes:Literal>"+fromDate+"</fes:Literal>"+
		// 	"</fes:PropertyIsGreaterThanOrEqualTo>"+
		// 	"<fes:PropertyIsLessThanOrEqualTo>"+
		// 		"<fes:Literal>DATE</fes:Literal>"+
		// 		"<fes:Literal>"+toDate+"</fes:Literal>"+
		// 	"</fes:PropertyIsLessThanOrEqualTo>"

		let dateFilter = nunjucks.renderString(templatedata.between , {field: 'DATE', greater: fromDate , lesser: toDate});

		let filterVal = this.filterOpen + this.filterAndOpen + bboxFilter + dateFilter + this.filterAndClose + this.filterClose;

		wfs_url += "&filter="+filterVal;

		//console.log("WFS : "+wfs_url);
		let final_url = "";        
        if(layer.service_type === "WFS")
        {
        	final_url = wfs_url;
        }
        else
        {
        	final_url = query_url;
        }        
        //console.log("Type : "+layer.service_type);
        console.log("Final : "+final_url);	      
        //In React then() is to return a promise after an asynchronous being excuted, catch(err) is to get the error once the asynchronous task gose wrong
        axios.get(final_url)        
        .then((res) => {
        	console.log(typeof res.data);
            let data = this.state.layerData;
            if (res.data.features.length === 0) {
                console.log("Your search returned no results!");
            } else {
                data["Overdose Heatmap"] = res.data;
                let isVisible = this.state.visible;
                isVisible["Overdose Heatmap"] = true;
                this.setState({
                    layerData: data,
                    visible: isVisible
                });
            }
        })
        .catch((err) => {
            console.log("HTTP request failed with error: ", err);
        });
    }

    handleLayerStyleUpdate(name, field, value) {
		const { layerStyle } = this.state;
		if (layerStyle[name] && layerStyle[name][field] !== undefined) {
            layerStyle[name][field] = value;
            this.setState({
                layerStyle
            });
        }
    }

    getPointStyle(point, latlng) {
        let style = {
            radius: 4
        };
        return L.circleMarker(latlng, style);
    }
	
	initialFetchLayerData(layer){
		if (layer.type === "GeoJSON") {
			let query_url = layer.url + "/query?f=geojson&geometryType=esriGeometryEnvelope&geometryPrecision=4&Geometry=" + layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax + "&outFields=";

					for (let idx = 0; idx < layer.outFields.length; idx++) {
						if (idx === 0)
							query_url += layer.outFields[idx].name;
						else
							   query_url += "," + layer.outFields[idx].name;
		
					}

			if (typeof layer.crs != undefined){
				query_url += "&inSR=" + layer.crs;
			}

			if (typeof layer.time !== "undefined" && layer.time !== null){
				let interval = [];
				let timeProps = layer.time.properties;
				if (layer.time.type === "discrete"){
					let year = ""
					if (typeof timeProps.defaultInterval === "undefined"){
						year = Math.max.apply(null, timeProps.intervals);
					} else {
						year = timeProps.defaultInterval;
					}
					interval[0] = moment(year + "-01-01 00:00:00").format("YYYY-MM-DD HH:mm:ss")
					interval[1] = moment(year + "-12-31 23:59:59").format("YYYY-MM-DD HH:mm:ss")
					query_url += "&where=DATE >= DATE '" + interval[0]  + "' AND DATE <= DATE '" + interval[1] + "'";
				} else if (layer.time.type === "continuous") {
					if (typeof timeProps.defaultInterval === "undefined"){
						interval[0] = moment(timeProps.range[0]).format("YYYY-MM-DD HH:mm:ss")
						interval[1] = moment(timeProps.range[1]).format("YYYY-MM-DD HH:mm:ss")
					} else {
						interval[0] = moment(timeProps.defaultInterval[0]).format("YYYY-MM-DD HH:mm:ss")
						interval[1] = moment(timeProps.defaultInterval[0]).format("YYYY-MM-DD HH:mm:ss")
					}
					query_url += "&where=DATE >= DATE '" + interval[0]  + "' AND DATE <= DATE '" + interval[1] + "'";
				}
			}
			let data = this.state.layerData;
			data[layer.name] = null;
			this.setState({
				layerData: data
			});
			//WFS URL							
			let wfs_url = layer.wfs_url;	
			wfs_url += "&typename="+layer.wfs_typename+"&outputFormat=GEOJSON";
			let coord = layer.extent.XMin + "," + layer.extent.YMin + "," + layer.extent.XMax + "," + layer.extent.YMax;
			let filters = [];
			filters.push(nunjucks.renderString(templatedata.bbox , {coord : coord}));							
			if (typeof layer.time !== "undefined" && layer.time !== null){
				let interval = [];
				let timeProps = layer.time.properties;
				if (layer.time.type === "discrete"){
					let year = ""
					if (typeof timeProps.defaultInterval === "undefined"){
						year = Math.max.apply(null, timeProps.intervals);
					} else {
						year = timeProps.defaultInterval;
					}
					interval[0] = moment(year + "-01-01 00:00:00").format("YYYY-MM-DD HH:mm:ss")
					interval[1] = moment(year + "-12-31 23:59:59").format("YYYY-MM-DD HH:mm:ss")
					filters.push(nunjucks.renderString(templatedata.between , {field : 'DATE' , greater : interval[0] , lesser : interval[1]}));
					
				} else if (layer.time.type === "continuous") {
					if (typeof timeProps.defaultInterval === "undefined"){
						interval[0] = moment(timeProps.range[0]).format("YYYY-MM-DD HH:mm:ss")
						interval[1] = moment(timeProps.range[1]).format("YYYY-MM-DD HH:mm:ss")
					} else {
						interval[0] = moment(timeProps.defaultInterval[0]).format("YYYY-MM-DD HH:mm:ss")
						interval[1] = moment(timeProps.defaultInterval[0]).format("YYYY-MM-DD HH:mm:ss")
					}
					filters.push(nunjucks.renderString(templatedata.between , {field : 'DATE' , greater : interval[0] , lesser : interval[1]}));
				}
			}

			let result = "";

			if(filters.length > 1)
			{
				result += this.filterAndOpen;								
			}

			for(let i = 0 ; i < filters.length ; i++)
					result += filters[i];
			if(filters.length > 1)
				result += this.filterAndClose;

			result = this.filterOpen + result + this.filterClose;
			wfs_url += "&filter="+result;
			// console.log(layer.name + " " + wfs_url);
			let final_url = "";
			if(layer.service_type === "WFS")
				final_url = wfs_url;
			else
				final_url = query_url;
			//console.log("Final REST : ("+layer.name+") "+query_url);
			//console.log("Final WFS : ("+layer.name+") "+wfs_url);
			//console.log("Type : "+layer.service_type);
			console.log("Final : ("+layer.name+") "+final_url);
			axios.get(final_url)
			.then((res) => {
			   let data = this.state.layerData;
			   data[layer.name] = res.data;
				if(typeof layer.symbol !== "undefined" && layer.symbol !== null){
				if(typeof layer.symbol.properties === "undefined"){
					layer.symbol["properties"] = {}
				}
			   if (layer.symbol.type === "gradColor") {
					let layerSymbol = this.state.layerSymbol;
					layerSymbol[layer.name] = layer.symbol;
					let symbolProps = layerSymbol[layer.name].properties
					symbolProps = this.setGradColorStyle(layer.name, data[layer.name], symbolProps)
					let mark = {};
					let step = Math.floor((symbolProps.range[1]-symbolProps.range[0]) / symbolProps.nClasses);
					let i = symbolProps.range[0];
					while (i < symbolProps.range[1]) {
						mark[i] = i;
						i = i + step;
					}
					layerSymbol[layer.name].properties = symbolProps;
					this.setState({
						layerSymbol: layerSymbol,
					})
				} else if (layer.symbol.type === "categorical"){
					let layerSymbol = this.state.layerSymbol;
					layerSymbol[layer.name] = layer.symbol;
					let symbolProps = layerSymbol[layer.name].properties
					let newProps = this.setCategoricalColorStyle(layer.name, data[layer.name], symbolProps.fieldName, symbolProps.fieldLabel, symbolProps.colorScale)
					Object.assign(symbolProps, newProps)
					layerSymbol[layer.name].properties = symbolProps;
					this.setState({
						layerSymbol: layerSymbol,
					})								
				} else {
					let layerSymbol = this.state.layerSymbol;
					layerSymbol[layer.name] = layer.symbol;
					this.setState({
						layerSymbol: layerSymbol,
					})		
				}
			} // END if(typeof layer.symbol !== "undefined" && layer.symbol !== null)
				this.setState({
					layerData: data
				});
				
			})
			.catch((err) => {
				console.log("HTTP request failed with error: ", err);
			});
		} else if (layer.type === "TileLayer"){
			let urls = this.state.urls;
			urls[layer.name] = layer.url;
			let data = this.state.layerData;
			data[layer.name] = {};
			this.setState({
				urls: urls,
				layerData: data
			});
		}

		if(typeof layer.time !== "undefined"){
			let layerTime = this.state.layerTime
			layerTime[layer.name] = layer.time;
			if (typeof layerTime[layer.name].properties === "undefined"){
				layerTime[layer.name].properties = {}
			}
			let timeProps = layerTime[layer.name].properties;
			if (layerTime[layer.name].type === "discrete"){
				timeProps["range"] = [Math.min.apply(null, timeProps.intervals), Math.max.apply(null, timeProps.intervals)]
				if (typeof timeProps.defaultInterval === "undefined"){
					timeProps["defaultInterval"] = Math.max.apply(null, timeProps.intervals);
				}
				layerTime[layer.name]["currentInterval"] = timeProps.defaultInterval;
			} else {
				if (typeof timeProps.range === "undefined"){
					timeProps["range"] = ["1900-01-01","2099-12-31"]
				}
				if (typeof timeProps.defaultInterval === "undefined"){
					timeProps["defaultInterval"] = timeProps.range;
				}
				layerTime[layer.name]["currentInterval"] = timeProps.defaultInterval;
			}
			layerTime[layer.name].properties = timeProps;

			this.setState({
				layerTime: layerTime,
			});
		}
		
		let layerStyle = this.state.layerStyle;
		layerStyle[layer.name] = layer.style;
		this.setState({
			layerStyle: layerStyle,
		});
		return layer
	}						
	
	
	componentDidUpdate(prevProps, prevState) {
		if(this.layerToggle){
			this.layerToggle = false;
			let isVisible = this.state.visible;
			let layers = this.state.layers;
			let urlLayers = []
			for(let i in layers){
				let layer = layers[i]
				let name = layer.name
				let id = layer.id
				if(isVisible[name]){
					if(!Object(this.state.layerData).hasOwnProperty(name)){
						let updatedLayer = this.initialFetchLayerData(layer);
						layers[i] = updatedLayer;	
					}
					urlLayers.push(id)
				}
			}
			
			this.setState({
				layers: layers,
				urlLayers: JSON.stringify(urlLayers)
			})
		}
	}

    /*In React the componentDidMount method runs after the component output has been rendered to the DOM
    asynchronous tasks always goes here, like use axios to bring in http requests
    In line 180, map() is a useful function in React to go through each element of array and do something */
    componentDidMount() {
        
		var acknowledgeButton = document.getElementById("acknowledgement")
		acknowledgeButton.addEventListener("click", this.handleAcknowledgement);
		
		let layers = [];

		let zoom = this.state.url.searchParams.get("zoom");
		let lat = this.state.url.searchParams.get("lat");
		let lng = this.state.url.searchParams.get("lng");
		let urlLayers = this.state.url.searchParams.get("layers");
		let layerlist = []
		if(urlLayers){
			layerlist = JSON.parse(urlLayers);
		}

		let tempLayers = JSON.parse(JSON.stringify(config.layers));
		let isVisible = {};
		for(let name in tempLayers){
			let layer = tempLayers[name];
			let id = layer.id;
			if(layerlist.length > 0){
				if(layerlist.includes(id)){
					isVisible[name] = true;
				} else {
					isVisible[name] = false;
				}
			} else {
				isVisible[name] = layer.visible;
			}
		}

		for (let name in tempLayers){
			let layer = tempLayers[name];					
			if ( !isVisible[name] ){
				layers.push(layer);
			} else {
				let updatedLayer = this.initialFetchLayerData(layer)
				layers.push(updatedLayer);
			}
		};

	    let newUrlLayers = [];
		for (let i in layers) {
			let layer = layers[i];
			let name = layer.name
			let id = layer.id
			if(isVisible[name]){
				newUrlLayers.push(id)
			}
		}

		let newState = {
			layers: layers,
			visible: isVisible,
			urlLayers: JSON.stringify(newUrlLayers)
		}
		
		if(zoom){
			newState['zoom'] = parseInt(zoom);
			newState['urlZoom'] = parseInt(zoom);
		}
		if(lat){
			newState['lat'] = parseFloat(lat);
			newState['urlLat'] = parseFloat(lat);
		}
		if(lng){
			newState['lng'] = parseFloat(lng);
			newState['urlLng'] = parseFloat(lng);
		}

		this.setState(newState);

		this.initialMountInProgress = false

	}; // END componentDidMount()  

    /*onMove function will excute whenever a user make a move or zoom in and out,
    after each movement and zoom change the url, use lat,lng,zoom instead of bounds */
    onMove(event) {
        let zoom = event.target.getZoom();
        let lat = event.target.getCenter().lat;
        let lng = event.target.getCenter().lng;

        this.setState({
			urlZoom: zoom,
			urlLat: lat,
			urlLng: lng,
		});

    }

    render() {
        let layerControl = [];
		
		var loading = document.getElementById("loading");
		loading.style.visibility = "visible";
		if(this.verifyDataReady()){
			loading.style.visibility = "hidden";

            for (var i in this.state.layers) {

				let layer = this.state.layers[i]
                if (!this.state.visible[layer.name]) {
                    continue;
                }
                let component = null;

				
				if(layer.type === "TileLayer"){
                    component =
                        <TileLayer 
							key = {Math.random() * 100}
							crossOrigin = {true}
							attribution = ""
							url = { this.state.urls[layer.name] }
							opacity = { this.state.layerStyle[layer.name].fillOpacity }
							zIndex = { 1000 }
							//interval = { this.state.layerTime[layer.name].currentInterval.toString()}
							errorTileUrl = { blankTile }
                        />
				}
                else {
					component = <GeoJSON 
						data={this.state.layerData[layer.name].features}
                    	// TBD - define fixed unique identifiers per layer (don't rely on random keys)
                        key = { Math.random() * 100 }
                        pointToLayer = { this.getPointStyle }
						interactive = { (layer.name === "Franklin County boundary") ? false : true }
						style = {(feature) => { 
							let layerStyle = this.state.layerStyle;
							if (typeof this.state.layerSymbol[layer.name] !== 'undefined' && this.state.layerSymbol[layer.name] !== null){
								let layerSymbol = this.state.layerSymbol[layer.name];
								if (layerSymbol.type === "gradColor"){
									let symbolProps = layerSymbol.properties;
									if(typeof symbolProps.suppressedDataValue !== "undefined" && symbolProps.suppressedDataValue != null && feature.properties[symbolProps.fieldName] == symbolProps.suppressedDataValue){
										layerStyle[layer.name].fillColor = symbolProps.suppressedDataColor
									} else {
										layerStyle[layer.name].fillColor = symbolProps.setFillColor(feature.properties[symbolProps.fieldName]).hex();
									}
								} else if (layerSymbol.type === "categorical"){
									let symbolProps = layerSymbol.properties;
									layerStyle[layer.name].fillColor = symbolProps.setFillColor(feature.properties[symbolProps.fieldName]);
								}
								if (layerSymbol.shape === "line"){
									layerStyle[layer.name].color = layerStyle[layer.name].fillColor;
								}
							}
							this.setState['layerStyle'] = layerStyle
							return this.setStyle(feature, layer.name);
						}} 
                        onEachFeature={(feature,feature_layer) => {
                            if(layer.popup){
                                let popup = "";
                                let num_fields = layer.outFields.length;
								for(let idx = 0;idx<num_fields;idx++){
									let key = layer.outFields[idx].name;
									let val = feature.properties[key];
									let label = layer.outFields[idx].alias;
									if (typeof layer.outFields[idx].units !== "undefined" && layer.outFields[idx].units !== null){
										label = label + " (" + layer.outFields[idx].units + ")";
									}
									if(val === undefined || val === null){
										continue;
									} else {
										val = this.formatValue(layer, key, val);
										if( val === null ){
											popup += "";
										} else {
											popup += "<strong>" + label + ":</strong> " + val + "<br />";
										}
                                    }
                                }
                                feature_layer.bindPopup(popup);
                            }
                            if(layer.tooltip){
                                let tooltipField = layer.tooltipField;
								let label = tooltipField.alias;
								if (typeof tooltipField.units !== "undefined" && tooltipField.units !== null){
									label = label + " (" + tooltipField.units + ")";
								}
                                let tool = feature.properties[tooltipField.name];
                                if (tool === null || tool === undefined){
									tool = "<strong>" + tooltipField.alias + ":</strong> N/A <br />";
								} else {
									tool = this.formatValue(layer, tooltipField.name, tool);
                                    // if (tooltipField.format === "decimals"){
                                        // tool = tool.toFixed(tooltipField.decimals);
                                    // } else if (tooltipField.format === "date"){
										// let d = new Date(tool);
										// tool = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear() ;
                                    // } else if (tooltipField.format === "url"){
												// tool = "<a href='" + tool + "'>" + tool + "</a>"
									// } else if (tooltipField.format === "percent"){
										// if (typeof tooltipField.decimals !== "undefined" && tooltipField.decimals !== null){
											// tool = parseFloat(tool).toFixed(tooltipField.decimals);
										// }
										// tool = tool.toString() + "%";
									// } else if (tooltipField.format === "currency"){												
										// let decimals = 2;
										// if (typeof tooltipField.decimals !== "undefined" && tooltipField.decimals !== null){
											// decimals = tooltipField.decimals;
										// }
										// tool = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: decimals }).format(tool);
									//}									
									tool = "<strong>" + label + ":</strong> " + tool;
								}
                                feature_layer.bindTooltip(tool, {sticky: true});
                            }
                        }} // END onEachFeature
					/>;  // END <GeoJSON
                } // END else (other layer type)
                layerControl.push(component);
			};  // END for (var i in this.state.layers)
		}

        window.history.pushState('', '', this.state.url.origin + this.state.url.pathname + `?zoom=${this.state.urlZoom}&lat=${this.state.urlLat}&lng=${this.state.urlLng}&layers=${this.state.urlLayers}`);

        /*On line 562, this is how we prop the variables in state to subcomponent
        When we want to prop some value to childcomponent like FocalSideBar,
        we assign this.state.xxx to a variable, after some configuration we can use them within the <FocalSideBar>
        This time we have layerData zipChange censusChange etc, more explaination will show in focalSideBar.js*/

        return (
			<div id="map-container">
                <Map
                    center = { [this.state.lat, this.state.lng] }
                    zoom = { this.state.zoom }
                    zoomAnimation = { true }
                    zoomControl = { false }
                    onMoveEnd = { this.onMove }
                >
					<ZoomControl id="zoomControl" position = "bottomright" />
					<TileLayer
						attribution = "Tiles &copy; Esri &mdash; Esri, HERE, Garmin, USGS, Intermap, INCREMENT P, NRCan, METI, NGCC, OpenStreetMap contributors, and the GIS User Community"
						url = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
					/>
					{layerControl}
                </Map >
                <FocalSidebar 
					layerData = { this.state.layerData }
                    layerStyle = { this.state.layerStyle }
                    layerSymbol = { this.state.layerSymbol }
					layerTime = { this.state.layerTime }
                    zipChange = { this.handleZipChange }
                    censusChange = { this.handleCensusChange }
                    odEventsChange = { this.handleOdEventsChange }
                    odHeatmapChange = { this.handleOdHeatmapChange }
                    providerChange = { this.handleProviderChange }
                    visible = { this.state.visible }
                    onLayerToggle = { this.handleLayerToggle }
                    onLayerStyleUpdate = { this.handleLayerStyleUpdate }
                    onLayerSetTop = { this.handleLayerSetTop }
					onExport = { this.handleExport }
					includeLocalLayers = { this.state.includeLocalLayers }
                />
            </div>
		);

    } // END render()
} // class SimpleExample

export default withRouter(SimpleExample);
